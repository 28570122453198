import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Pagination, PaginationProps } from '@ui/pagination';
import { TableWrapper } from '@common/components/layout/Table/tableWrapper';
import { TABLE_ID, TableIdType } from '@modules/transactions/constants/TableId';
import { useAppMobile } from '@common/selectors/app.selectors';
import { useGameAutoBetPaused } from '@modules/games/Game/store/gameAutobet/gameAutobet.selectors';
import { LOADING_IDS } from '@common/constants/fetch/loading.ids';
import { TableWrapperProps } from '@common/components/layout/Table/types/TableWrapperProps';
import { autobetPause } from '@modules/games/Game/store/gameAutobet/gameAutobet.actions';
import { useOnMount } from '@common/hooks/useOnMount';
import { isFunction } from '@common/methods/isFunction';
import { useStateEffect } from '@common/hooks/useStateEffect';
import { useAppDispatch } from '../../../hooks/store/useAppDispatch';
import {
	useTransactionsAnimation,
	useTransactionsMeta,
} from '../../store/transactions/transactions.selectors';
import transactionService from '../../../../modules/transactions/transactionsService';
import { usePrevious } from '../../../hooks/render/usePrevious';
import { TABLE_MOCKS } from '../../../mocks/tableMocks';
import {
	getTransactionsThunk,
	GetTransactionsThunkProps,
} from '../../store/transactions/thunks/getTransactions.thunk';

type GetData = (
	id: TableIdType,
	page?: number,
	disable?: boolean,
	refresh?: boolean,
	getDataProps?: GetTransactionsThunkProps['props'],
) => void;

export interface TransactionsTableProps {
	id: TableIdType;
	getData?: GetData;
	refreshInterval?: number;
	loadingId?: LOADING_IDS;
	limit?: number;
	getDataProps?: Record<string, any>;
	transactions?: TableWrapperProps['data'];
	sortCallback?: TableWrapperProps['sortCallback'];
	noResultsText?: TableWrapperProps['noResultsText'];
	filterCallback?: TableWrapperProps['sortCallback'];
	columns?: TableWrapperProps['columns'];
	classic?: TableWrapperProps['classic'];
	disableCompareKeysGeneration?: boolean;
}

// TODO:
// - commented pagination

const useTableTransactions = (props: TransactionsTableProps) => {
	const transactions_ = useTransactionsAnimation(props.id);

	const getTransactions = useCallback(() => {
		const transactions =
			props.transactions ||
			transactions_ ||
			(TABLE_MOCKS?.[props.id]?.length ? TABLE_MOCKS[props.id] : []);

		const limited = props.limit
			? transactions?.slice(0, props.limit)
			: transactions;

		return isFunction(props.filterCallback)
			? props.filterCallback(limited)
			: limited;
	}, [props, transactions_]);

	return useStateEffect(getTransactions, (a, b) =>
		transactionService.isListDifferent(
			a,
			b,
			!props.disableCompareKeysGeneration
				? transactionService.getRowCompareKeys(props.id, a)
				: null,
		),
	);
};

export const TransactionsTable: FC<TransactionsTableProps> = (props) => {
	const updateIntervalRef = useRef(null);

	const isMobile = useAppMobile();
	const autoBetPaused = useGameAutoBetPaused();
	const meta = useTransactionsMeta(props.id);
	const transactions = useTableTransactions(props);
	const prevId = usePrevious(props.id);
	const prevRefreshInterval = usePrevious(props.refreshInterval);
	const prevGetDataProps = usePrevious(props.getDataProps);

	const pagination = Number(meta?.last_page) > 1;

	const dispatch = useAppDispatch();

	const getData: GetData = useCallback(
		(id, page, disable, refresh) => {
			console.log('getData', id, page, disable, refresh, props);
			if (props.getData) {
				return props.getData(id, page, disable, refresh, props.getDataProps);
			}

			if (!id) {
				// console.log('No ID of transactions data');
				// sentryError('No ID of transactions data', {
				//   id,
				//   page,
				//   disable,
				//   refresh,
				// });
				return;
			}
			dispatch(
				getTransactionsThunk({
					id,
					page,
					disable,
					refresh,
					props: props.getDataProps,
				}),
			);
		},
		[dispatch, props],
	);

	const pauseAutobet = useCallback(
		(bool) => {
			return dispatch(autobetPause(bool));
		},
		[dispatch],
	);

	const isAutobetPaused = useCallback(() => {
		return props.id === TABLE_ID.myBets && pagination;
	}, [pagination, props.id]);

	const handlePauseAutobet = useCallback(() => {
		const bool = isAutobetPaused();
		if (!!bool === !autoBetPaused) {
			pauseAutobet(bool);
		}
	}, [autoBetPaused, isAutobetPaused, pauseAutobet]);

	const fetchData = useCallback(
		(id: TableIdType, page, lastId?: TableIdType, refresh?: boolean) => {
			getData(id, page, false, refresh);
			if (!lastId) {
				return;
			}
			getData(lastId, page, true, refresh);
		},
		[getData],
	);

	const startUpdatesInterval = useCallback(
		(lastTableID?: TableIdType, refresh?: boolean) => {
			// console.log('startUpdateInterval', props.id, lastTableID, updateInterval, props.refreshInterval);
			fetchData(props.id, undefined, lastTableID, refresh);
			if (!props.refreshInterval || pagination) {
				return;
			}
			// console.log('startUpdatesInterval', props.id, updateInterval);
			if (!updateIntervalRef.current) {
				updateIntervalRef.current = setInterval(() => {
					// console.log('interval', props.id);
					fetchData(props.id, undefined, undefined, true);
				}, props.refreshInterval);
			}
		},
		[fetchData, props.id, pagination, props.refreshInterval],
	);

	const clearUpdatesInterval = useCallback(() => {
		// console.log('clearUpdatesInterval', props.id, updateInterval);
		if (updateIntervalRef.current) {
			clearInterval(updateIntervalRef.current);
			updateIntervalRef.current = undefined;
		}
	}, []);

	const restartInterval = useCallback(
		(lastTableID?: TableIdType) => {
			clearUpdatesInterval();
			startUpdatesInterval(lastTableID, true);
		},
		[clearUpdatesInterval, startUpdatesInterval],
	);

	const onClickPage: PaginationProps['onClickPage'] = useCallback(
		(page) => {
			getData(props.id, page);
		},
		[getData, props.id],
	);

	useOnMount(() => {
		handlePauseAutobet();
		startUpdatesInterval();

		return () => {
			clearUpdatesInterval();
			getData(props.id, undefined, true);
			pauseAutobet(false);
		};
	});

	useEffect(() => {
		if (
			prevId !== props.id ||
			transactionService.isDataDifferent(props.getDataProps, prevGetDataProps)
		) {
			handlePauseAutobet();
			restartInterval(prevId);
		}
	}, [
		handlePauseAutobet,
		prevGetDataProps,
		prevId,
		props.getDataProps,
		props.id,
		restartInterval,
	]);

	useEffect(() => {
		if (prevRefreshInterval !== props.refreshInterval) {
			restartInterval();
		}
	}, [prevRefreshInterval, props.refreshInterval, restartInterval]);

	if (!props.id) {
		return null;
	}
	return (
		<TableWrapper
			data={transactions}
			classic={props.classic}
			highlightRow={true}
			id={props.id}
			sortCallback={props.sortCallback}
			noResultsText={props.noResultsText || 'No transactions'}
			loadingId={props.loadingId}
			noScroll={true}
			columns={props.columns}
			isMobile={isMobile}
		>
			<Pagination meta={meta} onClickPage={onClickPage} />
		</TableWrapper>
	);
};
