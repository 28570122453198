import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';
import { CurrencyType } from '@legacyApp/types/wallet/CurrencyType';
import transactionService from '../../../../transactions/transactionsService';
import { GameType } from '../../constants/availableGames';
import { GameStrategy } from '../../types/GameStrategy';
import { AutoBetMode } from '../game/thunks/handleBet.thunk';

export const useGameAutoBetIsRolling = (game: GameType) =>
	useAppSelector<boolean>(
		(state) => state.gameAutobet?.autoBetRolling[game],
		shallowEqual,
	);
export const useGameAutoBetActive = (game: GameType) =>
	useAppSelector<AutoBetMode | boolean>(
		(state) => state.gameAutobet?.active[game],
		shallowEqual,
	);
export const useGameAutoBetMode = () =>
	useAppSelector<string>((state) => state.gameAutobet?.mode, shallowEqual);

export const useGameAutoBetCurrency = (game: GameType) =>
	useAppSelector<CurrencyType>(
		(state) => state.gameAutobet?.currency[game],
		shallowEqual,
	);

export const useGameAutoBetStats = () =>
	useAppSelector<boolean>(
		(state) => state.gameAutobet?.autoStats,
		shallowEqual,
	);

export const useGameAutoBetRollsCounter = (game: GameType) =>
	useAppSelector<number>(
		(state) => state.gameAutobet?.rollsCounter[game],
		shallowEqual,
	);

export const useGameAutoBetConfigEasy = (game?: GameType) =>
	useAppSelector(
		(state) => state.gameAutobet?.configEasy[game || state.game?.activeGame],
		(previous, next) => !transactionService.isDataDifferent(previous, next),
	);

export const useGameAutoBetPaused = () =>
	useAppSelector<boolean>((state) => state.gameAutobet?.pause, shallowEqual);

export const useGameAutoBetStrategies = () =>
	useAppSelector<GameStrategy[]>(
		(state) => state.gameAutobet?.strategies,
		(previous, next) => !transactionService.isListDifferent(previous, next),
	);

export const useGameAutoBetActiveStrategy = (game?: GameType) =>
	useAppSelector<string>(
		(state) =>
			state.gameAutobet?.activeStrategy[game || state.game?.activeGame],
		(previous, next) => !transactionService.isDataDifferent(previous, next),
	);
