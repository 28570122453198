import { actionSimple } from '@legacyApp/client/modules/app/actionService';
import { CurrencyType } from '@legacyApp/types/wallet/CurrencyType';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { GameType } from '../../constants/availableGames';
import { RollRule } from '../../types/RollRule';
import { AutoBetMode } from '../game/thunks/handleBet.thunk';
import {
	AUTOBET,
	AUTOBET_CLEAR,
	AUTOBET_PAUSE,
	AUTOBET_PLAY,
	AUTOBET_RESET_TO_DEFAULT,
	AUTOBET_ROLLING,
	AUTOBET_SET_ACTIVE,
	AUTOBET_SET_AUTO_STATS,
	AUTOBET_SET_CONFIG,
	AUTOBET_SET_CONFIG_EASY,
	AUTOBET_SET_CURRENCY,
	AUTOBET_SET_DEFAULTS,
	AUTOBET_SET_EDIT_MODAL,
	AUTOBET_SET_ID,
	AUTOBET_SET_MANUAL,
	AUTOBET_SET_MODE,
	AUTOBET_SET_PAUSE,
	AUTOBET_SET_PAUSE_DATA,
	AUTOBET_SET_ROLLS,
	AUTOBET_SET_ROLLS_COUNTER,
	AUTOBET_STOP,
	AUTOBET_STOPPED,
	AUTOBET_SUMMARY_CONNECTION_ERROR,
	AUTOBET_UPDATE_CONFIG_EASY,
	DELETE_AUTOBET_STRATEGY,
	DISABLE_CHECK_AUTOBET_STRATEGY,
	EDIT_AUTOBET_STRATEGY,
	GET_AUTOBET_STRATEGIES,
	SAVE_AUTOBET_STRATEGY,
	SET_ACTIVE_AUTOBET_STRATEGY,
	SET_AUTOBET_STRATEGIES,
} from './gameAutobet.constants';

export const autobet = actionSimple(AUTOBET);

export const autobetPause = actionSimple(AUTOBET_PAUSE);

export const autobetSetPause = actionSimple(AUTOBET_SET_PAUSE);

export const autobetSetPauseData = actionSimple(AUTOBET_SET_PAUSE_DATA);

export const saveAutobetStrategy = (payload, setActive) => ({
	type: SAVE_AUTOBET_STRATEGY,
	payload,
	setActive,
});

export const editAutobetStrategy = actionSimple(EDIT_AUTOBET_STRATEGY);

export const setAutobetStrategies = actionSimple(SET_AUTOBET_STRATEGIES);

export const getAutobetStrategies = actionSimple(GET_AUTOBET_STRATEGIES);

export const deleteAutobetStrategy = actionSimple(DELETE_AUTOBET_STRATEGY);

export const setActiveAutobetStrategy = actionSimple(
	SET_ACTIVE_AUTOBET_STRATEGY,
);

export const disableCheckAutobetStrategy = actionSimple(
	DISABLE_CHECK_AUTOBET_STRATEGY,
);

export const getAutobetSessionLength = (game) => {
	return (dispatch, getState) => {
		const gameSession = getState().gameSession;
		return roundNumber(
			parseFloat(gameSession.lostAuto[game]) +
				parseFloat(gameSession.wonAuto[game]),
			0,
		);
	};
};

export const autobetSummaryConnectionError = (
	bet,
	data = null,
	onlyAlert = false,
) => ({
	type: AUTOBET_SUMMARY_CONNECTION_ERROR,
	bet,
	data,
	onlyAlert,
});

export const autobetSetRolls = actionSimple(AUTOBET_SET_ROLLS);

export const autobetSetRollsCounter = actionSimple(AUTOBET_SET_ROLLS_COUNTER);

export const autobetSetActive = actionSimple<{
	game: GameType;
	value: AutoBetMode | boolean;
}>(AUTOBET_SET_ACTIVE);

export const autoBetRolling = actionSimple(AUTOBET_ROLLING);

export interface AutobetPlayProps {
	game: GameType;
	currency: CurrencyType;
	rule?: RollRule;
	stopped?: boolean;
	manualConfirmed?: boolean;
}

export const autobetPlay = actionSimple<AutobetPlayProps>(AUTOBET_PLAY);

export const autobetStop = actionSimple(AUTOBET_STOP);

export const autobetStopped = actionSimple(AUTOBET_STOPPED);

export const autobetClear = actionSimple(AUTOBET_CLEAR);

export const autobetSetMode = actionSimple(AUTOBET_SET_MODE, {
	lsKey: LocalStorageKeys.AUTOBET_MODE,
});

export const autobetSetConfig = actionSimple(AUTOBET_SET_CONFIG);

export const autobetSetEditModal = actionSimple(AUTOBET_SET_EDIT_MODAL);

export const autobetSetConfigEasy = actionSimple(AUTOBET_SET_CONFIG_EASY);

export const autobetUpdateConfigEasy = actionSimple(AUTOBET_UPDATE_CONFIG_EASY);

export const autobetSetAutoStats = actionSimple(AUTOBET_SET_AUTO_STATS);

export const autobetSetId = (game, id) => ({
	type: AUTOBET_SET_ID,
	game,
	id,
});

export const autobetSetCurrency = (game, payload) => ({
	type: AUTOBET_SET_CURRENCY,
	game,
	payload,
});

export const autobetSetManual = actionSimple(AUTOBET_SET_MANUAL);

export const autobetResetToDefault = actionSimple(AUTOBET_RESET_TO_DEFAULT);
export const autobetSetDefaults = actionSimple(AUTOBET_SET_DEFAULTS);
