import { TableIdType } from '@modules/transactions/constants/TableId';
import { TransactionsResponseType } from '@modules/transactions/constants/TransactionsResponse.type';
import { GameType } from '@modules/games/Game/constants/availableGames';
import { useAppSelector } from '../../../hooks/store/useAppSelector';
import transactionService from '../../../../modules/transactions/transactionsService';

export const useTransactionsAnimation = <T = any>(id: TableIdType) => {
	let dataStored = [];
	let prev = false;

	return useAppSelector<TransactionsResponseType<T>['data']>(
		(state) => {
			const isAnimating = !!Object.keys(
				state.game?.betsInAnimation?.[state.game.activeGame] ?? {},
			)?.length;
			const data = state.transactions?.[id];

			if (isAnimating !== prev) {
				prev = isAnimating;
			}

			if (isAnimating !== prev || !dataStored?.length) {
				dataStored = data;
			}

			return isAnimating ? dataStored : data;
		},
		(previous, next) =>
			!transactionService.isListDifferent(
				previous,
				next,
				transactionService.getRowCompareKeys(id, previous),
			),
	);
};

export const useTransactions = <T = any>(id: TableIdType, limit?: number) =>
	useAppSelector<TransactionsResponseType<T>['data']>(
		(state) => {
			if (limit && state.transactions?.[id]?.length > limit) {
				return state.transactions[id].slice(0, limit);
			}
			return state.transactions?.[id];
		},
		(previous, next) =>
			!transactionService.isListDifferent(
				previous,
				next,
				transactionService.getRowCompareKeys(id, previous),
			),
	);

export const useTransactionsPerGameActive = (id: TableIdType, game: GameType) =>
	useAppSelector<boolean>((state) => !!state.transactions[id][game]?.length);

export const useTransactionsPerGame = <T = any>(
	id: TableIdType,
	game: GameType,
) =>
	useAppSelector<TransactionsResponseType<T>['data']>(
		(state) => state.transactions[id][game],
		(previous, next) =>
			!transactionService.isListDifferent(
				previous,
				next,
				transactionService.getRowCompareKeys(id, previous),
			),
	);

export const useTransactionsMeta = (id: TableIdType) =>
	useAppSelector(
		(state) => state.transactions.meta[id],
		(previous, next) =>
			transactionService.isDuplicate(previous, next, [
				'current_page',
				'total',
				'last_page',
			]),
	);
