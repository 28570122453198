import React, { FC, useCallback } from 'react';
import { Button, ButtonProps } from '@ui/button';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { TransactionsMetaType } from '@modules/transactions/constants/TransactionsResponse.type';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { Size } from '@uiTypes/Size';
import { PaginationStyled } from './Pagination.styled';

export interface PaginationProps extends ButtonProps {
	onClickPage: (page: number) => void;
	meta?: TransactionsMetaType;
}

export const Pagination: FC<PaginationProps> = ({
	onClickPage,
	meta,
	size,
}) => {
	const { current_page, last_page } = meta || {};

	const nextDisabled = current_page === last_page;
	const prevDisabled = current_page === 1;
	const paginationDisabled =
		!last_page || (typeof last_page === 'number' && last_page < 2);

	const handleClick = useCallback(
		(page: number) => {
			// console.log(number, this.props);
			if (page === current_page) {
				return;
			}
			onClickPage(page);
		},
		[current_page, onClickPage],
	);

	const nextPage = useCallback(() => {
		if (nextDisabled) {
			return;
		}
		handleClick(current_page + 1);
	}, [current_page, handleClick, nextDisabled]);

	const prevPage = useCallback(() => {
		if (prevDisabled) {
			return;
		}
		handleClick(current_page - 1);
	}, [current_page, handleClick, prevDisabled]);

	if (paginationDisabled) {
		return null;
	}

	return (
		<PaginationStyled>
			<Button
				name="pagination-prev"
				disabled={prevDisabled}
				onClick={prevPage}
				className="pagination__element"
				label={trans({ label: 'Previous page' })}
				size={size || Size.MEDIUM}
			/>
			<Button
				name="pagination-active"
				styleType={ButtonStyleType.CLEAR}
				label={current_page}
				size={size || Size.MEDIUM}
			/>
			<Button
				name="pagination-next"
				disabled={nextDisabled}
				onClick={nextPage}
				label={trans({ label: 'Next page' })}
				className="pagination__element"
				size={size || Size.MEDIUM}
			/>
		</PaginationStyled>
	);
};
