import React, { FC, PropsWithChildren, useCallback } from 'react';
import { columnsService } from '@modules/table/columnsService';
import { useStateEffect } from '@common/hooks/useStateEffect';
import transactionService from '../../../../modules/transactions/transactionsService';
import { TableWrapperProps } from './types/TableWrapperProps';
import { StyledTableWrapper } from './components/Table.styled';
import { Table } from './components/table';

export const TableWrapper: FC<PropsWithChildren<TableWrapperProps>> = ({
	children,
	...props
}) => {
	const getColumns = useCallback(() => {
		return props.columns || columnsService.getColumns(props.id);
	}, [props.columns, props.id]);
	const columns = useStateEffect(getColumns, (a, b) =>
		transactionService.isListDifferent(a, b, ['id']),
	);

	const getRowCompareKeys = useCallback(() => {
		return (
			props.rowCompareKeys ||
			transactionService.getRowCompareKeys(props.id, props.data)
		);
	}, [props.data, props.id, props.rowCompareKeys]);
	const rowCompareKeys = useStateEffect(getRowCompareKeys, (a, b) =>
		transactionService.isListDifferent(a, b),
	);

	const getTableCompareKeys = useCallback(() => {
		return (
			props.tableCompareKeys ||
			transactionService.getTableCompareKeys(props.id, props.data)
		);
	}, [props.data, props.id, props.tableCompareKeys]);
	const tableCompareKeys = useStateEffect(getTableCompareKeys, (a, b) =>
		transactionService.isListDifferent(a, b),
	);

	return (
		<StyledTableWrapper>
			<Table
				{...props}
				columns={columns}
				tableCompareKeys={tableCompareKeys}
				rowCompareKeys={rowCompareKeys}
			/>
			{children}
		</StyledTableWrapper>
	);
};
